import React from 'react';
import './DownloadInstallerIDItem.sass';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getTenantId } from 'utils/tenantUtil';

import { ActionButton } from 'components/ActionButton/ActionButton';
import { DownloadIcon } from 'components/Icons/DownloadIcon';
import { format, parse } from 'date-fns';
import { useLazyQuery } from '@apollo/client';
import { GET_INSTALLERID_QUERY } from 'Queries/Queries';
import { downloadPdfFromBase64 } from 'utils/FileUtil';
import { BRANCH_TYPE, BRANCH_TYPE_TO_GERMAN_NAME, TENANT_ID } from 'global/constants';

export const DownloadInstallerIDItem = ({ documentInformation, parsedData, expert }) => {
  const { name } = documentInformation;

  const [fetchDocument, { loading }] = useLazyQuery(GET_INSTALLERID_QUERY);

  const prepareInstallerIdData = () => {
    const type = documentInformation.type;
    const guest = documentInformation.guest;

    const variables = {};

    variables.entryType = guest ? 'GUEST' : 'MAIN';
    variables.branchType = type;
    variables.companyName1 = parsedData.companyName1;
    variables.companyName2 = parsedData.companyName2;
    variables.companyName3 = parsedData.companyName3;
    variables.companyStreet = parsedData.companyStreet;
    variables.companyHouseNumber = parsedData.companyHouseNumber;
    variables.companyPostal = parsedData.companyPostal;
    variables.companyCity = parsedData.companyCity;
    variables.companyId = parsedData.companyId;
    variables.expertName = expert.firstname + ' ' + expert.lastname;
    variables.downloadDate = format(new Date(), 'dd.MM.yyyy');
    const validity = type === BRANCH_TYPE.GAS ? expert.gasValidityDate : expert.energyValidityDate;
    const formatedDate = format(parse(validity, 'dd.MM.yyyy', new Date()), 'dd.MM.yyyy');
    variables.endOfValidity = formatedDate;
    variables.gridnetProvider =
      type === BRANCH_TYPE.GAS
        ? parsedData.gridProviderGas.name
        : parsedData.gridProviderEnergy.name;
    variables.companyType =
      type === BRANCH_TYPE.GAS ? parsedData.companyTypeGas : parsedData.companyTypeEnergy;

    variables.limitation =
      type === BRANCH_TYPE.GAS ? parsedData.limitationGas : parsedData.limitationEnergy;
    variables.meterInstallation =
      type === BRANCH_TYPE.GAS
        ? parsedData.meterInstallationGas
        : parsedData.meterInstallationEnergy;

    variables.referenceNumber = parsedData.referenznummer_strom;
    variables.canPlombieren = parsedData.canReseal; // needs to stay "canPlombieren" for BE compatibility

    return variables;
  };

  const getFilename = () => {
    const type = documentInformation.type;
    const date = format(new Date(), 'yyyy-MM-dd');
    const businessUnit = documentInformation.name;
    const name = expert.firstname.charAt(0) + '_' + expert.lastname;
    const validity = type === BRANCH_TYPE.GAS ? expert.gasValidityDate : expert.energyValidityDate;
    const formatedDate = format(parse(validity, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    if (documentInformation.guest) {
      return `${parsedData.companyId}_${businessUnit}_${name}_bis_${formatedDate}`;
    } else if (getTenantId() === TENANT_ID.BAG) {
      return `${parsedData.companyId}_BAG_${BRANCH_TYPE_TO_GERMAN_NAME[type]}_Stamm_${name}_${formatedDate}`;
    } else {
      return `${date}_${businessUnit}_Ausweis_Haupt-Eintragung_${name}_bis_${formatedDate}`;
    }
  };

  const fillAndDownloadID = async () => {
    const installerIdData = prepareInstallerIdData();
    const downloadFileName = getFilename();
    try {
      const result = await fetchDocument({
        fetchPolicy: 'no-cache',
        variables: {
          installerIdInput: {
            installerIdData: installerIdData,
            installerIdTemplateId: documentInformation.templateId,
          },
        },
      });

      downloadPdfFromBase64(result.data.installerId.base64, downloadFileName);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      className={classNames(
        'download-installer-id-item col-xs-3',
        `download-installer-id-item__${
          documentInformation.type === BRANCH_TYPE.GAS ? 'gas' : 'energy'
        }`
      )}
      onClick={fillAndDownloadID}>
      <ActionButton
        title={name}
        loading={loading}
        className="download-installer-id-item__download-button"
        inverted
        icon={<DownloadIcon />}>
        {name}
      </ActionButton>
    </div>
  );
};

DownloadInstallerIDItem.displayName = 'DownloadInstallerIDItem';

DownloadInstallerIDItem.propTypes = {
  documentInformation: PropTypes.object.isRequired,
  parsedData: PropTypes.object.isRequired,
  expert: PropTypes.any,
};

DownloadInstallerIDItem.defaultProps = {};
