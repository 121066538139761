import React from 'react';

export const DOCUMENT_NAME_TO_ID = {
  businessRegistrationChange: 13,
  liabilityInsuranceProof: 14,
  businessEquipmentConfirmation: 15,
  installerContractOwner: 16,
  craftCard: 17,
  commercialRegisterExtract: 18,
  gridOperatorRegistration: 19,
  employmentProof: 20,
  installerContractGas: 21,
  trainingCertificateExtension: 22,
  employmentProofResponsible: 23,
  installerContractGasResponsible: 24,
  qualificationProofElectricity: 25,
  qualificationProofGas: 26,
  treiCertificateElectricity: 27,
  trgiCertificate2018: 28,
  trgiCertificate100h: 29,
  installerContractMeterEnergy: 30,
  installerContractMeterGas: 31,
  installerContractSealing: 32,
};

export const SHARED_DOCUMENTS = {
  [DOCUMENT_NAME_TO_ID.businessRegistrationChange]: {
    id: DOCUMENT_NAME_TO_ID.businessRegistrationChange,
    title: 'Gewerbean-/ummeldung',
    name: 'businessRegistrationChange',
    optional: false,
    uploadName: 'Date_Gewerbeanmeldung',
  },
  [DOCUMENT_NAME_TO_ID.liabilityInsuranceProof]: {
    id: DOCUMENT_NAME_TO_ID.liabilityInsuranceProof,
    title: 'Nachweis über das Bestehen einer Betriebshaftpflichtversicherung',
    infoText: 'Mit ausgewiesenen Tätigkeitsfeld: Strom / Gas',
    name: 'liabilityInsuranceProof',
    optional: false,
    uploadName: 'Date_Versicherung',
  },
  [DOCUMENT_NAME_TO_ID.businessEquipmentConfirmation]: {
    id: DOCUMENT_NAME_TO_ID.businessEquipmentConfirmation,
    title:
      'Selbstbestätigung Betriebsausstattung (mit Unterschrift der verantwortlichen Fachkraft)',
    name: 'businessEquipmentConfirmation',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    optional: false,
    uploadName: 'Date_Betriebsausstattung',
  },
  [DOCUMENT_NAME_TO_ID.installerContractOwner]: {
    id: DOCUMENT_NAME_TO_ID.installerContractOwner,
    title: 'Installateurvertrag mit Unterschrift des Inhabers',
    name: 'installerContractOwner',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    optional: false,
    uploadName: 'Date_Installateurvertrag_Gas_Inhaber',
  },
  [DOCUMENT_NAME_TO_ID.craftCard]: {
    id: DOCUMENT_NAME_TO_ID.craftCard,
    title: 'Handwerkskarte',
    name: 'craftCard',
    infoText: <>(Vorder- und Rückseite – im Scheckkartenformat)</>,
    optional: false,
    uploadName: 'Date_HWK',
  },
  [DOCUMENT_NAME_TO_ID.commercialRegisterExtract]: {
    id: DOCUMENT_NAME_TO_ID.commercialRegisterExtract,
    title: 'Handelsregisterauszug',
    name: 'commercialRegisterExtract',
    optional: false,
    uploadName: 'Date_HRA',
  },
  [DOCUMENT_NAME_TO_ID.gridOperatorRegistration]: {
    id: DOCUMENT_NAME_TO_ID.gridOperatorRegistration,
    title: 'Eintragung bei einem anderen Netzbetreiber (Installateurausweis)',
    name: 'gridOperatorRegistration',
    optional: false,
    uploadName: 'Date_Gastausweis',
    infoText: (
      <>
        Bitte laden Sie Ihren aktuellen Installateurausweis hoch. Falls Sie keinen aktuellen Ausweis
        mit Anschrift haben, laden Sie Ihren alten Ausweis hoch, um den Antrag abzusenden. Reichen
        Sie zeitnah Ihren Ausweis nach.
      </>
    ),
    infoTextNE: <>Bitte laden Sie Ihren aktuellen Installateurausweis hoch.</>,
  },
  [DOCUMENT_NAME_TO_ID.employmentProof]: {
    id: DOCUMENT_NAME_TO_ID.employmentProof,
    title: 'Anstellungsnachweis der verantwortlichen Fachkraft',
    name: 'employmentProof',
    infoText: (
      <>
        z. B. mittels Anstellungsvertrag, Auszug Sozialversicherung, Bestätigung des
        Betriebsinhabers - von Ihrer verantwortlichen Fachkraft / Ihren verantwortlichen
        Fachkräften, wenn diese nicht gleichzeitig Inhaber bzw. Geschäftsführer der Firma ist / sind
      </>
    ),
    optional: false,
    uploadName: 'Date_Anstellungsnachweis',
  },
  [DOCUMENT_NAME_TO_ID.installerContractGas]: {
    id: DOCUMENT_NAME_TO_ID.installerContractGas,
    title: 'Installateurvertrag mit Unterschrift der verantwortlichen Fachkraft/Fachkräfte (Gas)',
    name: 'installerContractGas',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    optional: false,
    uploadName: 'Date_Installateurvertrag_Gas_Fachkraft',
  },
  [DOCUMENT_NAME_TO_ID.trainingCertificateExtension]: {
    id: DOCUMENT_NAME_TO_ID.trainingCertificateExtension,
    title: 'Fortbildungsnachweis zur Ausweisverlängerung',
    name: 'trainingCertificateExtension',
    optional: false,
    uploadName: 'Date_Fortbildungsnachweis',
  },

  [DOCUMENT_NAME_TO_ID.installerContractMeterEnergy]: {
    id: DOCUMENT_NAME_TO_ID.installerContractMeterEnergy,
    title: 'Vertrag  "Zählermontage durch Installateure" Strom',
    name: 'installerContractMeterEnergy',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    optional: false,
    uploadName: 'Date_Vertrag_ZDI_Strom',
  },
  [DOCUMENT_NAME_TO_ID.installerContractMeterGas]: {
    id: DOCUMENT_NAME_TO_ID.installerContractMeterGas,
    title: 'Vertrag  "Zählermontage durch Installateure" Gas',
    name: 'installerContractMeterGas',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    optional: false,
    uploadName: 'Date_Vertrag_ZDI_Gas',
  },
  [DOCUMENT_NAME_TO_ID.installerContractSealing]: {
    id: DOCUMENT_NAME_TO_ID.installerContractSealing,
    title: 'Vertrag "Nachplombierung"',
    name: 'installerContractSealing',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    optional: false,
    uploadName: 'Date_Vertrag_Nachplombierung',
  },

  //experts documents
  [DOCUMENT_NAME_TO_ID.employmentProofResponsible]: {
    id: DOCUMENT_NAME_TO_ID.employmentProofResponsible,
    title: 'Anstellungsnachweis der verantwortlichen Fachkraft',
    name: 'employmentProofResponsible',
    infoText: (
      <>
        z. B. mittels Anstellungsvertrag, Auszug Sozialversicherung, Bestätigung des
        Betriebsinhabers - von Ihrer verantwortlichen Fachkraft / Ihren verantwortlichen
        Fachkräften, wenn diese nicht gleichzeitig Inhaber bzw. Geschäftsführer der Firma ist / sind
      </>
    ),
    optional: false,
    uploadName: 'Date_Anstellungsnachweis_firstname_lastname',
  },
  [DOCUMENT_NAME_TO_ID.installerContractGasResponsible]: {
    id: DOCUMENT_NAME_TO_ID.installerContractGasResponsible,
    title: 'Installateurvertrag mit Unterschrift der verantwortlichen Fachkraft/Fachkräfte (Gas)',
    name: 'installerContractGasResponsible',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    optional: false,
    uploadName: 'Date_Installateurvertrag_Gas_Fachkraft',
  },
  [DOCUMENT_NAME_TO_ID.qualificationProofElectricity]: {
    id: DOCUMENT_NAME_TO_ID.qualificationProofElectricity,
    title: 'Qualifikationsnachweis(e) Strom',
    name: 'qualificationProofElectricity',
    optional: false,
    infoText: 'Hilfestellung dazu finden Sie in diesem Dokument',
    uploadName: 'Date_Quali_Strom_firstname_lastname',
  },
  [DOCUMENT_NAME_TO_ID.qualificationProofGas]: {
    id: DOCUMENT_NAME_TO_ID.qualificationProofGas,
    title: 'Qualifikationsnachweis(e) Gas',
    name: 'qualificationProofGas',
    optional: false,
    infoText: 'Hilfestellung dazu finden Sie in diesem Dokument',
    uploadName: 'Date_Quali_Gas_firstname_lastname',
  },
  [DOCUMENT_NAME_TO_ID.treiCertificateElectricity]: {
    id: DOCUMENT_NAME_TO_ID.treiCertificateElectricity,
    title: 'TREI-Schein (Strom)',
    name: 'treiCertificateElectricity',
    infoText: 'Wenn vorhanden, bitte mitsenden, sofern nicht in Meisterprüfung enthalten.',
    optional: true,
    uploadName: 'Date_TREI_firstname_lastname',
  },
  [DOCUMENT_NAME_TO_ID.trgiCertificate2018]: {
    id: DOCUMENT_NAME_TO_ID.trgiCertificate2018,
    title: 'Sachkundennachweis TRGI 2018: 1 Tageslehrgang (Gas)',
    name: 'trgiCertificate2018',
    infoText: 'Wenn vorhanden, bitte mitsenden, sofern nicht in Meisterprüfung enthalten.',
    optional: true,
    uploadName: 'Date_TRGI_firstname_lastname',
  },
  [DOCUMENT_NAME_TO_ID.trgiCertificate100h]: {
    id: DOCUMENT_NAME_TO_ID.trgiCertificate100h,
    title: 'Sachkundennachweis TRGI (100h) Lehrgang (Gas)',
    name: 'trgiCertificate100h',
    infoText: 'Wenn vorhanden, bitte mitsenden, sofern nicht in Meisterprüfung enthalten.',
    optional: true,
    uploadName: 'Date_TRGI_100h_firstname_lastname',
  },
};
