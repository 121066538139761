export const shngEnergyGuestGasMainFormData = {
  companyName1: 'HanseGas',
  companyName2: 'Netzbetreiber Testanlage (QA)',
  companyName3: '',
  companyOwner: '',
  companyId: '100000302526',
  companyStreet: 'Hansestr',
  companyHouseNumber: '123',
  companyPostal: '38225',
  companyCity: 'Salzgittter',
  companyRegion: '',
  gridProviderEnergy: 'SWKiel Netz GmbH',
  gridProviderGas: 'HanseGas GmbH',
  foreignCompanyIdEnergy: '',
  foreignCompanyIdGas: '',
  email: 'efixshng@gmail.com',
  phone: '123123',
  mobile: '',
  isAddressPublic: false,
  canSendMail: false,
  limitationGas: '',
  limitationEnergy: '',
  installerGasValidityDate: '31.12.2030',
  installerEnergyValidityDate: '31.12.2031',
  energyAgreement: false,
  gasAgreement: false,
  energyExtension: false,
  gasExtension: false,
  craftsChamberArea: '',
  craftsChamberNumber: '',
  craftsChamberDate: '',
  businessRegistrationLocation: '',
  businessRegistrationDate: '',
  commercialRegisterNumber: '',
  commercialRegisterDate: '',
  experts: [
    {
      firstName: 'Gas-aktiv',
      lastName: 'Strom-aktiv',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2031',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290608,
      isMainGas: true,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gas-aktiv',
      lastName: 'Strom-leer',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2031',
      birthdate: '',
      statusGas: 4,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290609,
      isMainGas: true,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'strom-gelöscht',
      lastName: 'gas-gelöscht',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2031',
      birthdate: '01.01.1949',
      statusGas: 1,
      statusEnergy: 1,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290610,
      isMainGas: true,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'strom-befristet',
      lastName: 'gas-befristet',
      expertGasValidityDate: '06.06.2025',
      expertEnergyValidityDate: '05.05.2025',
      birthdate: '01.01.1950',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290611,
      isMainGas: true,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gas-aktiv',
      lastName: 'Strom-in-Bearb',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2031',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 3,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290612,
      isMainGas: true,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Strom-inaktiv',
      lastName: 'Gas-aktiv',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2031',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 2,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290613,
      isMainGas: true,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Strom-inaktiv',
      lastName: 'Gas-inaktiv',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2031',
      birthdate: '',
      statusGas: 2,
      statusEnergy: 2,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290614,
      isMainGas: true,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gas-in-Bearb',
      lastName: 'Strom-in-Bearb',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2031',
      birthdate: '',
      statusGas: 3,
      statusEnergy: 3,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290615,
      isMainGas: true,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Strom-aktiv',
      lastName: 'Gas-inaktiv',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2031',
      birthdate: '',
      statusGas: 2,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290616,
      isMainGas: true,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Strom-befristet',
      lastName: 'Gas-aktiv',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '07.07.2027',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290617,
      isMainGas: true,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
  ],
  doesEnergyBranchExist: true,
  doesGasBranchExist: true,
  doesNeedAvaGuestPostal: false,
  avaGuestPostal: '',
  contracts: [],
  seals: [],
};
export const shngEnergyGuestGasMainInstallerData = {
  anredename: null,
  ausw_gas_datum: '2023-07-18',
  ausw_gas_gueltig: '2030-12-31',
  ausw_probe_gas: 0,
  ausw_probe_strom: 0,
  ausw_probe_wasser: 0,
  ausw_strom_datum: null,
  ausw_strom_gueltig: '2031-12-31',
  ausw_strom_matrizennr: null,
  ausw_wasser_datum: null,
  ausw_wasser_gueltig: null,
  ausweisnr_fremd_gas: null,
  ausweisnr_fremd_strom: null,
  ausweisnr_fremd_wasser: null,
  b_anrede: null,
  b_betriebsart_gas: {
    id: 1,
    name: 'Hauptbetrieb',
    geloescht: 0,
    pflegende_bu: '0',
  },
  b_betriebsart_strom: {
    id: 1,
    name: 'Hauptbetrieb',
    geloescht: 0,
    pflegende_bu: '0',
  },
  b_betriebsart_wasser: null,
  b_briefanrede: {
    id: 28,
    name: 'Guten Tag',
    geloescht: 0,
  },
  b_dezentrale_einheit_gas: null,
  b_dezentrale_einheit_strom: null,
  b_dezentrale_einheit_wasser: null,
  b_eintragung_als: {
    id: 1,
    name: 'Installateur',
    geloescht: 0,
  },
  b_eintragung_gas_als: {
    id: 1,
    name: 'Installateur',
    geloescht: 0,
  },
  b_eintragung_wasser_als: null,
  b_hwkbereich: null,
  b_netzbetreiber_gas: {
    id: 1568,
    name: 'HanseGas GmbH',
    geloescht: 0,
    ort: 'Quickborn',
    plz: '25450',
    strasse: 'Schleswag-HeinGas-Platz 1',
    netzbetreibernummer: 700339,
    eintragungsart: 1,
  },
  b_netzbetreiber_strom: {
    id: 736,
    name: 'SWKiel Netz GmbH',
    geloescht: 0,
    ort: 'Kiel',
    plz: '24116',
    strasse: 'Knooper Weg 75',
    netzbetreibernummer: 339,
    eintragungsart: 2,
  },
  b_netzbetreiber_wasser: null,
  b_standort: null,
  bemerkung: null,
  besichtigung_ausschuss: null,
  besichtigung_datum: null,
  besichtigung_eon: null,
  betriebshaftpflicht: 0,
  buero_hausnummer: '123',
  buero_land: null,
  buero_ort: 'Salzgittter',
  buero_ortsteil: null,
  buero_plz: '38225',
  buero_strasse: 'Hansestr',
  communication_email: null,
  eintragungsart_erg_gas: null,
  eintragungsart_erg_strom: null,
  eintragungsart_erg_wasser: null,
  eintragungsart_gas: 1,
  eintragungsart_strom: 2,
  eintragungsart_wasser: null,
  email: 'efixshng@gmail.com',
  ersteintragung: '2024-07-26',
  ersteintragung_gas: '2024-04-22',
  ersteintragung_wasser: null,
  fax: null,
  fax_werkstatt: null,
  firmeninhaber: null,
  firmenname1: 'HanseGas',
  firmenname2: 'Netzbetreiber Testanlage (QA)',
  firmenname3: null,
  geloescht_am: null,
  geloescht_gas_am: null,
  geloescht_wasser_am: null,
  gewerbeanmeldung_datum: null,
  gewerbeanmeldung_ort: null,
  hrabnummer: null,
  hwkdatum: null,
  hwknummer: null,
  id: 100000302526,
  importiert: null,
  in_verlaengerung: false,
  instv_gas_am: null,
  instv_wasser_am: null,
  lfdnr: null,
  musteranlage_gas: null,
  netzportal: 1,
  pflegende_bu: '20952',
  referenznummer_gas: null,
  referenznummer_strom: 'ReferenzTestStefan',
  referenznummer_wasser: null,
  register_letzte_aenderung: null,
  registergericht: null,
  richtlinien: null,
  richtlinien_version: null,
  status_gas: 4,
  status_gas_automatic: 1,
  status_strom: 4,
  status_strom_automatic: 1,
  status_wasser: null,
  status_wasser_automatic: null,
  telefon_geschaeft: '123123',
  telefon_mobil: null,
  telefon_werkstatt: null,
  werkstatt_hausnummer: null,
  werkstatt_land: null,
  werkstatt_ort: null,
  werkstatt_ortsteil: null,
  werkstatt_plz: null,
  werkstatt_strasse: null,
  zaehlermontage_gas: '00',
  zaehlermontage_strom: '00',
  zu_adressveroeff: 'Nein',
  zu_gasinfo: null,
  zu_infoversand: 'Nein',
  zu_kommunikation_mail: null,
  zu_netzanschlussportal: null,
  zu_technikinfo: null,
};
