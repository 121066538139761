import { isLocal } from 'utils/StageUtils';
import { avaconGuestBothFormData, avaconGuestBothInstallerData } from '../Testing/Avacon/guestBoth';
import { bagEnergyMainFormData, bagEnergyMainInstallerData } from '../Testing/Bag/energyMain';
import { edisEnergyGuestFormData, edisEnergyGuestInstallerData } from '../Testing/Edis/Edis';
import {
  shngEnergyGuestGasMainFormData,
  shngEnergyGuestGasMainInstallerData,
} from '../Testing/Shng/energyGuestGasMain';

const TEST_SUITES = {
  avaconGuestBoth: {
    formData: avaconGuestBothFormData,
    installerData: avaconGuestBothInstallerData,
  },
  bagEnergyMain: {
    formData: bagEnergyMainFormData,
    installerData: bagEnergyMainInstallerData,
  },
  edisEnergyGuest: {
    formData: edisEnergyGuestFormData,
    installerData: edisEnergyGuestInstallerData,
  },
  shngEnergyGuestGasMain: {
    formData: shngEnergyGuestGasMainFormData,
    installerData: shngEnergyGuestGasMainInstallerData,
  },
};

const shouldFillTestData = () => {
  return isLocal();
};

// const DEB_CURRENT_TEST_SUITE = TEST_SUITES.avaconGuestBoth;
const DEB_CURRENT_TEST_SUITE = TEST_SUITES.bagEnergyMain;

export { DEB_CURRENT_TEST_SUITE, shouldFillTestData };
