import { format, parse } from 'date-fns';

/**
 * Formats a date string to the German locale date format (dd.MM.yyyy).
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

/**
 * Converts a date string from 'YYYY-MM-DD' format to 'dd.MM.yyyy' format.
 *
 * @param {string} dateString - The date string in 'YYYY-MM-DD' format.
 * @returns {string} - The date string in 'dd.MM.yyyy' format.
 */
export const parseEIVDateStringToEfixDateString = (dateString) => {
  if (!dateString) {
    return '';
  }
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  return format(date, 'dd.MM.yyyy');
};
