const { CheckboxRow } = require('../CheckboxRow/CheckboxRow');
import React from 'react';
import PropTypes from 'prop-types';

import { BRANCH_TYPE } from 'global/constants';
import { canBranchRenderByStatus, isBranchGuest } from 'utils/InstallerUtils';

const ExtensionSection = ({ formik, installerStatusSummary }) => {
  return (
    <>
      {canBranchRenderByStatus(installerStatusSummary, BRANCH_TYPE.ENERGY) && (
        <CheckboxRow
          disabled={isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY)}
          hoverText={
            isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY)
              ? 'Nutzen Sie bitte die Taste "Verlängern" bei der Fachkraft'
              : undefined
          }
          name="energyExtension"
          label={'Ich möchte eine Verlängerung für die Sparte Strom beantragen.'}
          value={formik.values.energyExtension}
          onChange={formik.handleChange}
          editedHighlight></CheckboxRow>
      )}
      {canBranchRenderByStatus(installerStatusSummary, BRANCH_TYPE.GAS) && (
        <CheckboxRow
          name="gasExtension"
          disabled={isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS)}
          hoverText={
            isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS)
              ? 'Nutzen Sie bitte die Taste "Verlängern" bei der Fachkraft'
              : undefined
          }
          label={'Ich möchte eine Verlängerung für die Sparte Gas beantragen.'}
          value={formik.values.gasExtension}
          onChange={formik.handleChange}
          editedHighlight></CheckboxRow>
      )}
    </>
  );
};

ExtensionSection.propTypes = {
  formik: PropTypes.object,
  installerStatusSummary: PropTypes.object,
};

export { ExtensionSection };
