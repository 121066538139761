import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputRow } from 'components/FormHelper/InputRow';
import { FormSection } from 'components/FormHelper/FormSection';
import { getTenantId } from 'utils/tenantUtil';
import { InputField } from 'components/FormHelper/InputField';
import { checkKeyDown, FFormDebug } from 'utils/FormUtils';
import { useNESteps } from 'context/NEStepsContext';
import {
  NE_FIRST_SECTION_INPUTS,
  shouldNeFirstPageSectionFieldRender,
  shouldNeFirstPageSectionRender,
  checkErrors,
} from './NEFormUtility';

import { FormPageWrapper } from 'components/FormHelper/FormPageWrapper';

import { NEExpertTable } from 'components/NEComponents/NEExpertsTable/NEExpertsTable';
import { ActionButton } from 'components/ActionButton/ActionButton';
import PlusIcon from 'components/Icons/PlusIcon';
import { NENewExpertPopup } from 'components/NEComponents/NENewExpertPopup/NENewExpertPopup';
import { NEFormIV } from './NEFormIV';
import { NEAgreementSection } from 'components/NEForm/NEAgreementSection';
import { YNEStepFormPageSchema } from 'components/NEValidation/YNEStepFormPageSchema';
import { NE_ERROR_MESSAGE } from 'components/NEForm/Utils/NEError';

import { isRegisteringBranchGuest } from 'components/NEForm/Utils/NEDataUtils';
import { CURRENT_TEST_SUITE, shouldFillTestData } from 'components/NEForm/NETestdata/NETestSetup';
import { BRANCH_TYPE, NE_ACTIVE_STEP } from 'global/constants';

const NEFormPage = ({ energyAgreementLink, gasAgreementLink, setButtonErrorMessage }) => {
  const { setSubmittedValuesWithStep, getSubmittedValuesWithStep } = useNESteps();
  const [newExpertPopupOpen, setNewExpertPopupOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const initialValues = shouldFillTestData()
    ? CURRENT_TEST_SUITE.step2
    : NEFormIV(
        getSubmittedValuesWithStep(NE_ACTIVE_STEP.EMAIL_INPUT),
        getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION),
        getSubmittedValuesWithStep(NE_ACTIVE_STEP.FORM)
      );
  const formik = useFormik({
    validationSchema: YNEStepFormPageSchema(
      getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)
    ),
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
  });

  const { values, errors: formErrors } = formik;

  useEffect(() => {
    setSubmittedValuesWithStep(NE_ACTIVE_STEP.FORM, values);
  }, [values]);

  useEffect(() => {
    const errors = checkErrors(
      values,
      getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION),
      formErrors
    );
    setErrors(errors);
    if (errors.length > 0) {
      setButtonErrorMessage(NE_ERROR_MESSAGE);
    } else {
      setButtonErrorMessage('');
    }
  }, [values, formErrors]);

  return (
    <>
      <form
        onKeyDown={checkKeyDown}
        onSubmit={(e) => {
          e.preventDefault();
        }}>
        <FormPageWrapper>
          {NE_FIRST_SECTION_INPUTS.map((section) => {
            if (
              shouldNeFirstPageSectionRender(
                section.id,
                getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION),
                getTenantId()
              )
            ) {
              let extraInfo = section.infoText;
              if (
                section.id === 'companyNames' &&
                (isRegisteringBranchGuest(
                  BRANCH_TYPE.ENERGY,
                  getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)
                ) ||
                  isRegisteringBranchGuest(
                    BRANCH_TYPE.GAS,
                    getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)
                  ))
              ) {
                extraInfo +=
                  '\n\nDie Eintragungsdaten zu Firma und Fachkraft müssen den Daten auf Ihrem Installateurausweis entsprechen. Bitte aktualisieren Sie den Ausweis vor der Eintragung, falls erforderlich. Abweichende Daten können nicht eingetragen werden.';
              }
              return (
                <FormSection
                  key={section.id}
                  title={section.title}
                  subtitle={section.subtitle}
                  infoText={extraInfo}
                  tooltip={section.tooltip}
                  navigationTooltip={section.navigationTooltip}>
                  <InputRow>
                    {section.fields?.map((field) => {
                      return shouldNeFirstPageSectionFieldRender(
                        field.name,
                        getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)
                      ) ? (
                        <InputField
                          infoText={field.infoText}
                          key={field.name}
                          name={field.name}
                          fullWidth
                          label={`${field.fieldName}${field.mandatory ? '*' : ''}`}
                          variant="standard"
                          disabled={field.readOnly}
                          value={values[field.name]}
                          onChange={formik.handleChange}
                          error={Boolean(formik.errors[field.name])}
                          helperText={formik.errors[field.name] || ''}
                        />
                      ) : null;
                    })}
                  </InputRow>
                </FormSection>
              );
            } else return null;
          })}

          <FormSection title="Fachkräfte hinzufügen">
            <NEExpertTable
              formik={formik}
              errors={errors}
              branchSelectionValues={getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)}
            />
            <ActionButton
              inverted
              icon={<PlusIcon />}
              title={<div className="text-nowrap"> Fachkraft hinzufügen</div>}
              onClick={() => {
                setNewExpertPopupOpen(true);
              }}></ActionButton>
            <NENewExpertPopup
              branchSelectionValues={getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)}
              popupOpen={newExpertPopupOpen}
              setPopupOpen={setNewExpertPopupOpen}
              mainFormik={formik}></NENewExpertPopup>
          </FormSection>
          <FormSection>
            <NEAgreementSection
              formik={formik}
              branchSelectionValues={getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)}
              energyAgreementLink={energyAgreementLink}
              gasAgreementLink={gasAgreementLink}
            />
          </FormSection>
        </FormPageWrapper>

        <FFormDebug values={getSubmittedValuesWithStep(NE_ACTIVE_STEP.FORM)} />
      </form>
    </>
  );
};

NEFormPage.propTypes = {
  energyAgreementLink: PropTypes.string,
  gasAgreementLink: PropTypes.string,
  setButtonErrorMessage: PropTypes.func.isRequired,
};

export { NEFormPage };
