import React from 'react';
import PropTypes from 'prop-types';
import { SectionTitle } from './SectionTitle';
import { SectionSubtitle } from './SectionSubtitle';
import { IconButton } from '@material-ui/core';
import { InfoIcon } from 'components/Icons/InfoIcon';
import { SectionMidTitle } from './SectionMidTitle';
import { CustomTooltip } from 'components/CustomTooltip/CustomTooltip';
import './FormSection.sass';
export function FormSection(props) {
  return (
    <div className={'form-section' + (props.isExtension ? '__extension' : '')}>
      {props.title && (
        <div className="form-section__title-container">
          <SectionTitle>{props.title}</SectionTitle>
          {props.titleTooltip && (
            <CustomTooltip title={props.titleTooltip} placement="top">
              <IconButton className="form-section__title_tooltip_icon">
                <InfoIcon />
              </IconButton>
            </CustomTooltip>
          )}
        </div>
      )}
      {props.midTitle && <SectionMidTitle>{props.midTitle}</SectionMidTitle>}

      {props.subtitle && (
        <div className="section-subtitle">
          <SectionSubtitle>{props.subtitle}</SectionSubtitle>
          {props.tooltip && (
            <CustomTooltip title={props.tooltip} placement="top">
              <IconButton className="form-section__subtitle_tooltip_icon">
                <InfoIcon />
              </IconButton>
            </CustomTooltip>
          )}
          {props.navigationTooltip && props.navigationTooltip}
        </div>
      )}
      {props.infoText && <div className="form-section__info-text">{props.infoText}</div>}
      {props.children}
    </div>
  );
}

FormSection.propTypes = {
  title: PropTypes.string || PropTypes.object,
  midTitle: PropTypes.string,
  subtitle: PropTypes.string,
  infoText: PropTypes.string || PropTypes.node,
  children: PropTypes.node,
  tooltip: PropTypes.node,
  titleTooltip: PropTypes.node,
  isExtension: PropTypes.bool,
  navigationTooltip: PropTypes.node,
};
