import React from 'react';
import { NavigationTooltip } from 'components/NavigationTooltip/NavigationTooltip';
import { getTenantId } from 'utils/tenantUtil';
import { isArray, isEqual, isNull, isUndefined } from 'lodash';
import { ERRORS } from './Errors';
import { BRANCH_TYPE, INSTALLER_ENTRIES, TENANT_ID } from 'global/constants';
import { doesInstallerHaveBranch, isBranchGuest } from 'utils/InstallerUtils';

const CONTENT = {
  [TENANT_ID.EDI]: {
    TOOLTIP_LINK: 'https://www.e-dis-netz.de/de/meinauftragsportal.html',
  },
  [TENANT_ID.SHNG]: {
    TOOLTIP_LINK: 'https://www.sh-netz.com/de/meinauftragsportal.html',
  },
  [TENANT_ID.AVA]: {
    TOOLTIP_LINK: 'https://www.avacon-netz.de/de/meinauftragsportal.html',
  },
  [TENANT_ID.BAG]: {
    TOOLTIP_LINK: 'https://www.bayernwerk-netz.de/de/meinauftragsportal.html',
  },
};

export const DEB_FIRST_PAGE_SECTIONS = [
  {
    id: 'companyNames',
    title: 'Firmendaten',
    fields: [
      { name: 'companyName1', fieldName: 'Firmenname1', mandatory: true },
      { name: 'companyName2', fieldName: 'Firmenname2' },
      { name: 'companyName3', fieldName: 'Firmenname3' },
      { name: 'companyOwner', fieldName: 'Firmeninhaber' },
    ],
  },
  {
    id: 'companyStreetData',
    fields: [
      { name: 'companyStreet', fieldName: 'Straße', mandatory: true },
      { name: 'companyHouseNumber', fieldName: 'Hausnummer', mandatory: true },
    ],
  },
  {
    id: 'companyCity',
    fields: [
      { name: 'companyPostal', fieldName: 'PLZ', mandatory: true },
      { name: 'companyCity', fieldName: 'Ort', mandatory: true },
      { name: 'companyRegion', fieldName: 'Ortsteil' },
    ],
  },
  {
    id: 'avaExtraInfo',
    subtitle: 'Zuweisung Auftragsort',
    tooltip: (
      <div className="deb-form__ava-tooltip-table">
        <div>
          Diese Angabe benötigen wir für unsere interne Zuordnung. Sofern Sie die Anmeldung von
          mehreren Installationsanlagen in unserem Gebiet planen, geben Sie die PLZ eines beliebigen
          Auftragsortes an. Ihre Gasteintragung gilt für das gesamte Gebiet der Avacon Netz GmbH. In
          der Tabelle einige Beispiele
        </div>
        <table cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              <th>Standort</th>
              <th>PLZ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Burgwedel</td>
              <td>30938</td>
            </tr>
            <tr>
              <td>Gardelegen</td>
              <td>39638</td>
            </tr>
            <tr>
              <td>Gehrden</td>
              <td>30989</td>
            </tr>
            <tr>
              <td>Lüneburg</td>
              <td>21337</td>
            </tr>
            <tr>
              <td>Salzwedel</td>
              <td>29410</td>
            </tr>
            <tr>
              <td>Sarstedt</td>
              <td>31157</td>
            </tr>
            <tr>
              <td>Schöningen</td>
              <td>38364</td>
            </tr>
            <tr>
              <td>Syke</td>
              <td>28857</td>
            </tr>
            <tr>
              <td>Oschersleben</td>
              <td>39387</td>
            </tr>
            <tr>
              <td>WEVG / Salzgitter</td>
              <td>38226</td>
            </tr>
          </tbody>
        </table>
      </div>
    ),
    fields: [{ name: 'avaGuestPostal', fieldName: 'PLZ Auftragsort', mandatory: true }],
  },
  {
    id: 'companyContacts',
    subtitle: 'Kontaktdaten',
    navigationTooltip: (
      <NavigationTooltip
        className="form-contact-info-title-section__nav-item"
        title="Wenn Sie ihre Kontaktdaten ändern wollen, machen Sie dies bitte über das mAP Portal."
        cta="Zum mAP!"
        to={CONTENT[getTenantId()].TOOLTIP_LINK}
      />
    ),
    fields: [{ name: 'mobile', fieldName: 'Mobil' }],
  },
  {
    id: 'companyContacts2',
    fields: [
      { name: 'phone', fieldName: 'Telefon', mandatory: true },
      {
        name: 'email',
        fieldName: 'Email',
        readOnly: true,
        mandatory: true,
        infoText: 'Email nur im mAP änderbar',
      },
    ],
  },
  {
    id: 'craftChamberData',
    subtitle: 'Eintragung bei der Handwerkskammer',
    tooltip: 'Handwerkskarte',
    fields: [
      { name: 'craftsChamberArea', fieldName: 'Ausgestellt von' },
      { name: 'craftsChamberNumber', fieldName: 'Betriebsnummer' },
      { name: 'craftsChamberDate', fieldName: 'Austellungsdatum' },
    ],
  },
  {
    id: 'businessRegistrationData',
    subtitle: 'Gewerbean-/ummeldung',
    tooltip: 'von der zuständigen Gemeinde/Stadt',
    fields: [
      { name: 'businessRegistrationLocation', fieldName: 'Ort' },
      { name: 'businessRegistrationDate', fieldName: 'Ausstellungsdatum' },
    ],
  },
  {
    id: 'commercialRegistrationData',
    subtitle: 'Handelsregisterauszug',
    tooltip:
      'mit Nennung der aktuellen Inhaber/Geschäftsführer (bei Firmierung als „e. K.“, „GmbH“, „GmbH & Co. KG“; „AG“, etc.)',

    fields: [
      { name: 'commercialRegisterNumber', fieldName: 'HRA/-B-Nummer und Amtsgericht' },
      { name: 'commercialRegisterDate', fieldName: 'Auszug vom' },
    ],
  },
  {
    id: 'gridEnergyProvider',
    subtitle:
      'Eintragung bei ortsansässigem Netzbetreiber Strom (wenn abweichend vom aktuellen Netzbetreiber)',
    fields: [
      { name: 'gridProviderEnergy', fieldName: 'Netzbetreiber Sparte Strom' },
      { name: 'foreignCompanyIdEnergy', fieldName: 'Ausweis-Nummer' },
    ],
  },
  {
    id: 'gridGasProvider',
    subtitle:
      'Eintragung bei ortsansässigem Netzbetreiber Gas (wenn abweichend vom aktuellen Netzbetreiber)',
    fields: [
      { name: 'gridProviderGas', fieldName: 'Netzbetreiber Sparte Gas' },
      { name: 'foreignCompanyIdGas', fieldName: 'Ausweis-Nummer' },
    ],
  },
];

export const DEB_SECOND_PAGE_EXPERTS_SECTIONS = [
  {
    id: 'newExpertsGas',
    title: 'new experts gas',
    fields: ['newExpertsGas'],
  },
  {
    id: 'newExpertsEnergy',
    title: 'new experts energy',
    fields: ['newExpertsEnergy'],
  },
  {
    id: 'deletedExpertsEnergy',
    title: 'deleted experts energy',
    fields: ['deletedExpertsEnergy'],
  },
  {
    id: 'deletedExpertsGas',
    title: 'deleted experts gas',
    fields: ['deletedExpertsGas'],
  },
  {
    id: 'extendedExpertsGas',
    title: 'extended experts gas',
    fields: ['extendedExpertsGas'],
  },
  {
    id: 'extendedExpertsEnergy',
    title: 'extended experts energy',
    fields: ['extendedExpertsEnergy'],
  },
];

export const DEB_SECOND_PAGE_SECTIONS = [
  {
    id: 'energyDocuments',
    title: 'Nachweise Sparte Strom - Dokumentenupload',
    fields: [],
  },
  {
    id: 'companyName',
    fieldId: 'companyName',
    title: 'Firmenname - Dokumentenupload',
    fields: ['companyName1', 'companyName2', 'companyName3'],
  },
  {
    id: 'gasDocuments',
    title: 'Nachweise Sparte Gas - Dokumentenupload',
    fields: [],
  },
  ...DEB_SECOND_PAGE_EXPERTS_SECTIONS,
];

export const DOCUMENT_SECTIONS_IDS = [
  {
    id: 1,
    fields: ['companyName1', 'companyName2', 'companyName3'],
  },
  {
    id: 2,
    fields: ['companyOwner'],
  },
  {
    id: 3,
    fields: ['companyStreet', 'companyHouseNumber', 'companyPostal', 'companyCity'],
  },
  {
    id: 4,
    fields: ['mobile', 'phone'],
  },
  {
    id: 5,
    fields: ['craftsChamberArea', 'craftsChamberNumber', 'craftsChamberDate'],
  },
  {
    id: 6,
    fields: ['businessRegistrationLocation', 'businessRegistrationDate'],
  },
  {
    id: 7,
    fields: ['commercialRegisterNumber', 'commercialRegisterDate'],
  },
  {
    id: 8,
    fields: [
      'gridProviderEnergy',
      'foreignCompanyIdEnergy',
      'gridProviderGas',
      'foreignCompanyIdGas',
    ],
  },
  {
    id: 11,
    fields: ['energyExtension'],
  },
  {
    id: 12,
    fields: ['gasExtension'],
  },
  {
    id: 20,
    fields: ['seals'],
  },
  {
    id: 21,
    fields: ['contracts'],
  },
  {
    id: 22,
    fields: ['companyRegion'],
  },
  {
    id: 23,
    fields: ['deletedExpertGas'],
  },
  {
    id: 24,
    fields: ['deletedExpertEnergy'],
  },
];

const tenantId = getTenantId();

//functions part

export const shouldDebFirstPageSectionRender = (sectionId, installerStatusSummary) => {
  const avaGuestSection = ['avaExtraInfo'];
  const bagSectionsMain = [
    'craftChamberData',
    'businessRegistrationData',
    'commercialRegistrationData',
  ];
  const guestSections = ['gridEnergyProvider', 'gridGasProvider'];
  const guestSectionsGas = 'gridGasProvider';

  switch (tenantId) {
    case TENANT_ID.BAG:
      if (avaGuestSection.includes(sectionId)) {
        return false;
      }
      if (guestSections.includes(sectionId)) {
        if (guestSectionsGas === sectionId) {
          if (
            doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary) &&
            isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS)
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          if (
            doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary) &&
            isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY)
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      if (
        installerStatusSummary.installerEntry === INSTALLER_ENTRIES.GUEST_ONLY &&
        bagSectionsMain.includes(sectionId)
      ) {
        return false;
      }
      return true;
    default:
      if (bagSectionsMain.includes(sectionId)) {
        return false;
      } else if (avaGuestSection.includes(sectionId)) {
        if (
          tenantId === TENANT_ID.AVA &&
          installerStatusSummary.installerEntry === INSTALLER_ENTRIES.GUEST_ONLY
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (guestSections.includes(sectionId)) {
          if (guestSectionsGas === sectionId) {
            if (
              doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary) &&
              isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS)
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            if (
              doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary) &&
              isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY)
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
  }
  return true;
};

export const shouldDebFirstPageSectionFieldRender = (fieldName, installerStatusSummary) => {
  const bagFields = [
    'companyOwner',
    'craftsChamberArea',
    'craftsChamberNumber',
    'craftsChamberDate',
    'businessRegistrationLocation',
    'businessRegistrationDate',
    'commercialRegisterNumber',
    'commercialRegisterDate',
  ];
  const energyGuestFields = ['gridProviderEnergy', 'foreignCompanyIdEnergy'];
  const gasGuestFields = ['gridProviderGas', 'foreignCompanyIdGas'];
  switch (getTenantId()) {
    case TENANT_ID.BAG:
      if (energyGuestFields.includes(fieldName)) {
        return (
          doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary) &&
          isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY)
        );
      } else if (gasGuestFields.includes(fieldName)) {
        return (
          doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary) &&
          isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS)
        );
      } else {
        return true;
      }
    default:
      if (bagFields.includes(fieldName)) {
        return false;
      } else {
        if (energyGuestFields.includes(fieldName)) {
          return (
            doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary) &&
            isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY)
          );
        } else if (gasGuestFields.includes(fieldName)) {
          return (
            doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary) &&
            isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS)
          );
        } else {
          return true;
        }
      }
  }
};

export const shouldDebSecondPageSectionRender = (section, documentsTrigger) => {
  if (section.triggeredByGroupIds.some((id) => documentsTrigger.includes(id))) {
    return true;
  }
  return false;
};

export function calculateChangedValues(valuesFirstPage, initialValuesFirstPage) {
  const changedValue = Object.keys(valuesFirstPage).reduce((acc, key) => {
    if (isArray(valuesFirstPage[key])) {
      if (!isNull(initialValuesFirstPage[key]) && !isUndefined(initialValuesFirstPage[key])) {
        valuesFirstPage[key].forEach((elem, i) => {
          if (!isEqual(elem, initialValuesFirstPage[key][i])) {
            if (acc[key]) {
              acc[key] = [...acc[key], valuesFirstPage[key][i]];
            } else {
              acc[key] = [valuesFirstPage[key][i]];
            }
          }
        });
      } else {
        acc[key] = valuesFirstPage[key];
      }
    } else {
      if (!isEqual(valuesFirstPage[key], initialValuesFirstPage[key])) {
        acc[key] = valuesFirstPage[key];
      }
    }
    return acc;
  }, {});
  return changedValue;
}

export const createEmailSubject = (values, installerStatusSummary) => {
  const emailSubject = [];
  const documentsTriggers = values.documentsTrigger;
  const expertsTriggers = Array.from(
    new Set(
      Object.keys(values.expertTrigger)
        .map((expertKey) => {
          return values.expertTrigger[expertKey];
        })
        .flat(2)
    )
  );

  const changeEnergyTriggers = [9];
  const changeGasTriggers = [10];
  const extensionEnergyTriggers = [11];
  const extensionGasTriggers = [12];
  const bothTriggers = [1, 2, 3, 4, 5, 6, 7, 20, 21, 22];
  //logic for new experts
  if (
    expertsTriggers.some((expertTrigger) => {
      return changeEnergyTriggers.includes(expertTrigger);
    })
  ) {
    emailSubject.push('change');
    emailSubject.push(BRANCH_TYPE.ENERGY);
  }

  if (
    expertsTriggers.some((expertTrigger) => {
      return changeGasTriggers.includes(expertTrigger);
    })
  ) {
    emailSubject.push('change');
    emailSubject.push(BRANCH_TYPE.GAS);
  }

  //logic for extensions
  if (
    expertsTriggers.some((expertTrigger) => {
      return extensionGasTriggers.includes(expertTrigger);
    })
  ) {
    emailSubject.push('extension');
    emailSubject.push(BRANCH_TYPE.GAS);
  }
  if (
    expertsTriggers.some((expertTrigger) => {
      return extensionEnergyTriggers.includes(expertTrigger);
    })
  ) {
    emailSubject.push('extension');
    emailSubject.push(BRANCH_TYPE.ENERGY);
  }
  if (
    documentsTriggers.some((expertTrigger) => {
      return extensionEnergyTriggers.includes(expertTrigger);
    })
  ) {
    emailSubject.push('extension');
    emailSubject.push(BRANCH_TYPE.ENERGY);
  }

  if (
    documentsTriggers.some((expertTrigger) => {
      return extensionGasTriggers.includes(expertTrigger);
    })
  ) {
    emailSubject.push('extension');
    emailSubject.push(BRANCH_TYPE.GAS);
  }
  //logic for installer fields
  if (
    documentsTriggers.some((expertTrigger) => {
      return bothTriggers.includes(expertTrigger);
    })
  ) {
    emailSubject.push('change');
    if (doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary))
      emailSubject.push(BRANCH_TYPE.GAS);
    if (doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary))
      emailSubject.push(BRANCH_TYPE.ENERGY);
  }

  //distinct logic for grid/gas providers
  if (documentsTriggers.includes(8)) {
    const guestEnergy =
      doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary) &&
      isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY);
    const guestGas =
      doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary) &&
      isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS);
    emailSubject.push('change');
    if (guestEnergy) {
      emailSubject.push(BRANCH_TYPE.ENERGY);
      // emailSubject.push('energy_' + installerStatusSummary[BRANCH_TYPE.ENERGY].entryType);
    }
    if (guestGas) {
      emailSubject.push(BRANCH_TYPE.GAS);
      // emailSubject.push('gas_' + installerStatusSummary[BRANCH_TYPE.GAS].entryType);
    }
  }
  //custom logic for deleted experts
  if (expertsTriggers.includes(23)) {
    emailSubject.push('change');
    emailSubject.push(BRANCH_TYPE.GAS);
  }
  if (expertsTriggers.includes(24)) {
    emailSubject.push('change');
    emailSubject.push(BRANCH_TYPE.ENERGY);
  }

  //check for each branch the type of branch
  if (
    doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary) &&
    emailSubject.includes(BRANCH_TYPE.ENERGY)
  ) {
    emailSubject.push('energy_' + installerStatusSummary[BRANCH_TYPE.ENERGY].entryType);
  }
  if (
    doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary) &&
    emailSubject.includes(BRANCH_TYPE.GAS)
  ) {
    emailSubject.push('gas_' + installerStatusSummary[BRANCH_TYPE.GAS].entryType);
  }
  //creation of the string from the array values
  const emailSubjectSet = Array.from(new Set(emailSubject));
  let emailSubjectString = '[e-fix] ';
  if (emailSubject.includes(BRANCH_TYPE.ENERGY)) {
    emailSubjectString += 'Strom ';
  }
  if (emailSubjectSet.includes(BRANCH_TYPE.GAS)) {
    emailSubjectString += 'Gas ';
  }
  emailSubjectString += '- ';
  if (emailSubjectSet.includes('change')) {
    emailSubjectString += 'Änderung ';
  }
  if (emailSubjectSet.includes('extension')) {
    emailSubjectString += 'Verlängerung ';
  }
  emailSubjectString += '- ';
  if (emailSubjectSet.includes('energy_guest')) {
    emailSubjectString += 'Gast ';
  }
  if (emailSubjectSet.includes('energy_main')) {
    emailSubjectString += 'Stamm ';
  }
  if (emailSubjectSet.includes('gas_guest')) {
    if (emailSubjectString.indexOf('Gast ') === -1) emailSubjectString += 'Gast ';
  }
  if (emailSubjectSet.includes('gas_main')) {
    if (emailSubjectString.indexOf('Stamm ') === -1) emailSubjectString += 'Stamm ';
  }

  return emailSubjectString;
};

export const checkErrors = (values, initialValues, errors, requiredFields) => {
  const changedValues = calculateChangedValues(values, initialValues);

  if (Object.keys(errors).length === 0) {
    if (Object.keys(changedValues).length === requiredFields) {
      return ERRORS.NO_VALUES_CHANGED;
    } else if (errors.energyAgreement || errors.gasAgreement) {
      return ERRORS.DEFAULT;
    } else {
      return '';
    }
  } else {
    return ERRORS.DEFAULT;
  }
};
